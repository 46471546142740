
<template>
  <BackofficeBase :loader_prop="loader_prop">
    <DistributionPointMenu :point_id_prod="point_id_prod" />
     
      <div v-if="! show_ty">
      <form @submit="enduser_save">
          <div>
            <div>
              Email:
            </div>
            <div>
              <input v-model="email" type="text" v-bind:class="{input_error : email_input_error}" />
              <div class="input_error_txt" v-if="email_input_error">
                Email error
              </div>
            </div>
          </div>
          <div>
            <div>
              Phone:
            </div>
            <div>
              <input v-model="phone_number" type="text" v-bind:class="{input_error : phone_number_input_error}" />
              <div class="input_error_txt" v-if="phone_number_input_error">
                Phone error
              </div>
            </div>
          </div>
          <div>
            <div>
             Name:
            </div>
            <div>
              <input v-model="name" type="text" v-bind:class="{input_error : name_input_error}" />
              <div class="input_error_txt" v-if="name_input_error">
                Name error
              </div>
            </div>
          </div>
          <div class="submit_cont">
            <input type="submit" value="Save" />
          </div>
      </form>
    </div>
    <div v-if=" show_ty">
      "משתמש נוסף בהצלחה"<br>
      <router-link :to="{path : '/backoffice/viewing_endusers_admin', query : {'id' :id}}">חזור</router-link>
    </div>
    <div class="input_error_txt" v-if = " usere_xist ">
    המשתמש קיים
    </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";

export default {
  name: 'BackofficeAddEnduser',
  components: {
    BackofficeBase
  },
  data() {
    return {
      loader_prop : true,
      view_data : [],
      id : 0,
      email : '',
      name : '',
      phone_number :'',
      email_input_error : false,
      phone_number_input_error : false,
      name_input_error : false,
      show_ty : false,
      usere_xist :false
    }

  },
  async mounted() {
    this.id = this.$route.query.id
    let backendModel = new BackendModel()
    let view_data = await backendModel.backendRequest("/Api/service/backoffice/get_distribution_point", {"id": this.id})
    this.loader_prop = false
    this.view_data = view_data.data.view_data
    console.log(this.view_data)
  },
  methods : {
    async enduser_save(e) {
      e.preventDefault()
      // Validation
      let errs = 0
      this.email_input_error = false
      this.phone_number_input_error = false
      this.name_input_error = false

      if (this.email == '') {
        this.email_input_error = true
        errs++
      }
      if (this.phone_number == '') {
        this.phone_number_input_error = true
        errs++
      }
      if (this.name == '') {
        this.name_input_error = true
        errs++
      }

      if (errs == 0) {
        this.loader_prop = true
        let backendModel = new BackendModel()
        let params = {
          id : this.id,
          email : this.email,
          name : this.name,
          phone_number : this.phone_number
        }
        let res = await backendModel.backendRequest("/Api/service/backoffice/admin_add_user", params)
        if(res.data.res == "NOK"){
          console.log(res.data.res )
          this.loader_prop = false
          this.usere_xist = true
        }else{
          this.loader_prop = false
          this.show_ty = true
        }
      }
    }
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>

